import FormData from 'form-data';

import axios from 'axios';
axios.defaults.withCredentials = true;


export default class BlogService {

	getPosts() {
		return axios.get('https://advantex.uz/blog/getAllPosts').then(res => res.data);
	}

	editPost(post) {
		var data = new FormData()
		data.append('user_id', post.user_id)
		data.append('title', post.title)
		data.append('slug', post.slug)
		data.append('text_data', post.text_data)
		data.append('created_date', post.created_date)
		data.append('is_published', post.is_published)
		data.append('photo', post.photo)
		data.append('lang', post.lang)
		post.extra_images.forEach(element => {
			data.append('extra_images[]', element)
		});
		data.append("deletedImages[]", post.deleted_images)

		data.append('id', post.id)

		var config = {
			method: 'post',
			url: 'https://advantex.uz/blog/updatePost',
			data: data
		}

		return axios(config)
	}

	insertPost(post) {
		var data = new FormData()
		data.append('user_id', post.user_id)
		data.append('title', post.title)
		data.append('slug', post.slug)
		data.append('text_data', post.text_data)
		data.append('created_date', post.created_date)
		data.append('is_published', post.is_published)
		data.append('photo', post.photo)
		data.append('lang', post.lang)
		post.extra_images.forEach(element => {
			data.append('extra_images[]', element)
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/blog/insertPost',
			data: data
		}

		return axios(config)
	}

	deletePost(blog) {
		var data = JSON.stringify({
			"id": blog.id
		});

		var config = {
			method: 'delete',
			url: 'https://advantex.uz/blog/deletePost',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)
	}
}