<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" @click="openNew" />
						<Button label="Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedPosts || !selectedPosts.length" />
					</template>
                </Toolbar>
				<DataTable ref="dt" :value="posts" class="p-datatable-responsive-demo" v-model:selection="selectedPosts" dataKey="id" 
					:paginator="true" :rows="10" :filters="filters" :loading="loading1" :rowsPerPageOptions="[5,10,25]"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} posts">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Manage Posts</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="id" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="title" header="Title" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Title</span>
							{{slotProps.data.title}}
						</template>
					</Column>
					<Column field="author" header="Author" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Author</span>
							{{slotProps.data.first_name}}  {{slotProps.data.last_name}}
						</template>
					</Column>
					<Column field="date" header="Date" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Date</span>
							{{ slotProps.data.created_date }}
						</template>
					</Column>
					
					<Column field="lang" header="Language" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Language</span>
							{{ slotProps.data.lang }}
						</template>
					</Column>
					
					<Column field="inventoryStatus" header="Status" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Status</span>
							<span v-if="slotProps.data.is_published==1" class='post-badge status'>Published</span>
							<span v-else class='post-badge status'>Unpublished</span>
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editPost(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeletePost(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="postDialog" :style="{width: '800px'}" header="Blog Details" :modal="true" class="p-fluid">
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="title">Title</label>
							<InputText id="title" v-model.trim="post.title" required="true" autofocus :class="{'p-invalid': submitted && !post.title}" />
							<small class="p-invalid" v-if="submitted && !post.title">Title is required.</small>
						</div>
					</div>
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="slug">Slug</label>
							<InputText id="slug" v-model.trim="post.slug" required="true" autofocus :class="{'p-invalid': submitted && !post.slug}" />
							<small class="p-invalid" v-if="submitted && !post.slug">Slug is required.</small>
						</div>
					</div>
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-4">
							<label for="date">Date</label><br>
							<input type="date" v-model="post.created_date" class="p-inputtext p-component p-filled">
                    	</div>

						<div class="p-field p-col-4">
							<label for="date">Language</label><br>
							<Dropdown id="state" v-model="selectedItem" :options="dropdownItems" optionLabel="name" placeholder="Select One"></Dropdown>
							
                    	</div>

					
						<div class="p-field p-col-4">
							<label for="title">Status</label>
							<SelectButton v-model="statusOption" :options="statusOptions" optionLabel="name" />
						</div>
					</div>
                    <div class="p-field">
						<label for="content">Content</label>
                        <Editor v-model="post.text_data" editorStyle="height: 320px" required="true" id="content"/>
                    </div>
					<div class="p-field p-col-12 p-md-12">
						<label for="post_image">Post main image</label>
						<br>
						<img :src="'https://advantex.uz/public/assets/images/advantex/blog/' + post.photo" :alt="post.photo" class="blog-image-modal" v-if="post.photo" />
						<!-- <label v-else class="italic"> No photo </label> <br/> <br/> -->
						<input type="file" id="post_image" ref="file" @change="handleFileUpload"/>
						<!-- <Button label="+ Choose file" class="p-button-secondary" @click="onClickSelectImage" /> -->
					</div>
					
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12">
							<label for="gallery_image">Post extra images</label>
						</div>
						<div class="p-grid">
							<div class="p-field p-col-4 p-m-1 image-area" v-for="p in post.images" :key="p">
								<img :src="'https://advantex.uz/public/assets/images/advantex/blog/' + p.image_url" :alt="p.image_url" class="blog-image-modal p-field p-col-4 p-m-1" />
							 	<a :id="p.id" class="remove-image" @click="handleFileDelete" style="display: inline;">&#215;</a>
							</div>
						</div>
					</div>
					
					<div class="p-formgrid p=grid">
						<div class="p-field p-col">
							<label for="post_image">Upload extra images</label>
						</div>
						<div class="p-grid">
							<div class="p-field p-col-4 p-md-4" v-for="url in urls" :key="url">
								<img :src="url" :alt="url" class="blog-image-modal " />
							</div>
						</div>
						<input type="file" id="post_image" ref="file" multiple @change="handleExtraFileUpload"/>
					</div>
					
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="savePost" />
						<!-- <Button label="Publish" icon="pi pi-print" class="p-button-text" @click="savePost" /> -->
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePostDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="post">Are you sure you want to delete <b>{{post.title}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePostDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deletePost" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePostsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="post">Are you sure you want to delete the selected posts?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePostsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedPosts" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import BlogService from '../service/BlogService';

export default {
	data() {
		return {
			posts: null,
			postDialog: false,
			deletePostDialog: false,
			deletePostsDialog: false,
			post: {},
			selectedPosts: null,
			filters: {},
			submitted: false,
			statusOptions:[{name: 'Published', is_published:'1'}, {name: 'Unpublished', is_published:'0'}],
			statusOption:{},
			loading1: true,
			dropdownItems: [
				{name: 'UZ', code: 'UZ'},
				{name: 'RU', code: 'RU'},
				{name: 'EN', code: 'EN'}
			],
			selectedItem: null,
			urls:[],
			extra_images:[],
			deleted_images:[]
		}
	},
	postService: null,
	created() {
		this.postService = new BlogService();
	},
	mounted() {
		this.onMounted();
		this.urls = [];
		this.extra_images= [];
		this.deleted_images= [];
	},
	methods: {
		onMounted() {
			this.postService.getPosts().then(data => {
				if (data.isLogged) {
					this.posts = data.fetch_data
					this.loading1 = false
				} else {
					this.$router.push('Login')
				}
			});
		},
		openNew() {
			this.post = {};
			this.selectedItem = null;
			this.statusOption = this.statusOptions[1]
			this.submitted = false;
			this.postDialog = true;
			this.urls = [];
			this.extra_images= [];
			this.deleted_images= [];
		},
		hideDialog() {
			this.postDialog = false;
			this.submitted = false;
            console.log(this.post.created_date);
		},
		savePost() {
			this.submitted = true;
			this.post.is_published = this.statusOption.is_published
			this.post.lang = this.selectedItem.code
			this.post.user_id = this.$store.state.user.id
			this.post.extra_images = this.extra_images
			this.post.deleted_images = this.deleted_images

			if (this.post.id) {
				this.postService.editPost(this.post)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.postDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			}
			else {
				this.postService.insertPost(this.post)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.postDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			}

			this.postDialog = false;
			this.post = {};
		},
		editPost(post) {
			this.post = {...post};
			this.urls = [];
			this.extra_images = [];
			this.deleted_images = [];
			this.selectedItem = this.dropdownItems.find((item) => item.code==post.lang.toUpperCase());
			this.postDialog = true;
			this.statusOption = this.statusOptions.find(item => item.is_published == this.post.is_published)
		},
		confirmDeletePost(post) {
			this.post = post;
			this.deletePostDialog = true;
		},
		deletePost() {
			this.postService.deletePost(this.post)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.deletePostDialog = false;
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		},
		confirmDeleteSelected() {
			this.deletePostsDialog = true;
		},
		deleteSelectedPosts() {
			this.posts = this.posts.filter(val => !this.selectedPosts.includes(val));
			this.deletePostsDialog = false;
			this.selectedPosts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Posts Deleted', life: 3000});
		},
		handleFileUpload(event){
			const file = event.target.files[0]
			this.url = URL.createObjectURL(file)
			this.post.photo = event.target.files[0]
		},

		handleExtraFileUpload(event){
			event.target.files.forEach(element => {
				this.urls.push(URL.createObjectURL(element))
			});
			this.extra_images = event.target.files
			console.log(this.extra_images)
		},
		handleFileDelete(event){
			// console.log(event.target.id)
			// console.log(this.post.images)
			this.post.images.splice(this.post.images.findIndex(function(i){
				return i.id == event.target.id;
			}), 1);
			this.deleted_images.push(event.target.id)
			// console.log(this.deleted_images)

		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.post-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.image-area {
	position: relative;
	width: 40%;
	background: #333;
}
.image-area img{
	max-width: 100%;
	height: auto;
}

.blog-image-modal {
	width: 150px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 2rem 0;
	display: block;
}

.p-dialog .post-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}
.remove-image {
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	border-radius: 10em;
	padding: 2px 6px 3px;
	text-decoration: none;
	font: 700 21px/20px sans-serif;
	background: #555;
	border: 3px solid #fff;
	color: #FFF;
	box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3);
	text-shadow: 0 1px 2px rgba(0,0,0,0.5);
	-webkit-transition: background 0.5s;
	transition: background 0.5s;
}
.remove-image:hover {
	background: #E54E4E;
	padding: 3px 7px 5px;
	top: -11px;
	right: -11px;
}
.remove-image:active {
	background: #E54E4E;
	top: -10px;
	right: -11px;
}
.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.post-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-published {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-saved {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
